.ButtonGroup {
  display: inline-flex;
  vertical-align: middle;
  isolation: isolate;

  & > *:not([data-loading-wrapper]) {
    /* stylelint-disable-next-line primer/spacing */
    margin-inline-end: -1px;
    position: relative;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: var(--borderRadius-medium);
      border-bottom-left-radius: var(--borderRadius-medium);
    }

    &:last-child {
      border-top-right-radius: var(--borderRadius-medium);
      border-bottom-right-radius: var(--borderRadius-medium);
    }

    &:focus,
    &:active,
    &:hover {
      z-index: 1;
    }
  }

  /* if child is loading button */
  [data-loading-wrapper] {
    &:first-child {
      button,
      a {
        border-top-left-radius: var(--borderRadius-medium);
        border-bottom-left-radius: var(--borderRadius-medium);
      }
    }

    &:last-child {
      button,
      a {
        border-top-right-radius: var(--borderRadius-medium);
        border-bottom-right-radius: var(--borderRadius-medium);
      }
    }
  }

  [data-loading-wrapper] > * {
    /* stylelint-disable-next-line primer/spacing */
    margin-inline-end: -1px;
    position: relative;
    border-radius: 0;

    &:focus,
    &:active,
    &:hover {
      z-index: 1;
    }
  }
}
