/* Animation definition */
@keyframes tooltip-appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Tooltip {
  /* Overriding the default popover styles */
  display: none;

  &[popover] {
    position: absolute;
    width: max-content;
    max-width: 250px;
    /* stylelint-disable-next-line primer/spacing */
    padding: 0.5em 0.75em;
    margin: auto;

    /* for scrollbar */
    overflow: visible;
    clip: auto;
    /* stylelint-disable-next-line primer/typography */
    font: normal normal 11px/1.5 var(--fontStack-system);
    color: var(--tooltip-fgColor);
    text-align: center;
    word-wrap: break-word;
    white-space: normal;
    background: var(--tooltip-bgColor);
    border: 0;
    border-radius: var(--borderRadius-medium);
    opacity: 0;
    -webkit-font-smoothing: subpixel-antialiased;
    inset: auto;
  }

  /* class name in chrome is :popover-open */
  &[popover]:popover-open {
    display: block;
  }

  /* class name in firefox and safari is \:popover-open */
  &[popover].\\:popover-open {
    display: block;
  }

  @media (forced-colors: active) {
    outline: 1px solid transparent;
  }

  /* This is needed to keep the tooltip open when the user leaves the trigger element to hover tooltip */
  &::after {
    position: absolute;
    right: 0;
    left: 0;
    display: block;
    height: var(--overlay-offset);
    content: '';
  }

  /* South, East, Southeast, Southwest after */
  &[data-direction='n']::after,
  &[data-direction='ne']::after,
  &[data-direction='nw']::after {
    top: 100%;
  }

  &[data-direction='s']::after,
  &[data-direction='se']::after,
  &[data-direction='sw']::after {
    bottom: 100%;
  }

  &[data-direction='w']::after {
    position: absolute;
    bottom: 0;
    left: 100%;
    display: block;
    width: 8px;
    height: 100%;
    content: '';
  }

  /* East before and after */
  &[data-direction='e']::after {
    position: absolute;
    right: 100%;
    bottom: 0;
    display: block;
    width: 8px;
    height: 100%;
    /* stylelint-disable-next-line primer/spacing */
    margin-left: -8px;
    content: '';
  }

  /* Animation styles */
  &:popover-open,
  &:popover-open::before {
    animation-name: tooltip-appear;
    animation-duration: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    animation-delay: 0s;
  }

  /* Animation styles */
  &.\\:popover-open,
  &.\\:popover-open::before {
    animation-name: tooltip-appear;
    animation-duration: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    animation-delay: 0s;
  }
}
